import React,{useEffect, useState} from 'react'
import { useParams } from "react-router-dom";
import DropTarget from './DropTarget';
import { Gauge } from '../../../../../../../Redux/chartsSlice';
import { useDispatch } from 'react-redux';
import { FormatFormula } from '../Formula';
import { AddWidget } from 'src/Redux/widgetSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const ItemTypes = {
    NUMBER: 'number',
  };

const GaugeDrop = () => {

    const dispatch = useDispatch()
    const params = useParams()

    const [minValue, setMinValue] = useState({ item: null, formula: 'SUM' });
    const [maxValue, setMaxValue] = useState({ item: null, formula: 'SUM' });


    const submitWidget = (event) => {

      const dashboardId = params?.id;
    

      if (minValue && maxValue) {
        
        const minimumValue = {col:minValue.item?.value,formula:minValue?.formula};
        const maximumValue = {col:maxValue.item?.value,formula:maxValue?.formula}
        const metaData = {
          dashboardId,
          minimumValue,
          maximumValue,
          dataId:minValue.item?.dataId,
          chartType: "gauge",  
        };
  
        dispatch(AddWidget(metaData)).then((resp)=>{
          if(resp?.payload.message == "Widget added successfully"){
            toast.success(`Widget Saved Successfully`, {
              position: "top-right",
             },5000)
          }
          else{
            toast.error(`Error while saving widget`, {
              position: "top-right",
             },5000)
          }
        })
      
      // Here you might also call an API or update some state with this metaData
      }else{
        console.warn("DATA MISSING")
      }
    
    };


    useEffect(()=>{
      const payload = {
        type:"minValue",
        item:{
          dataId:minValue?.item?.dataId,
          column:minValue?.item?.column,
          formula:FormatFormula(minValue?.formula)
        }
      }
      dispatch(Gauge(payload))
    },[minValue])
    
    useEffect(()=>{
      const payload = {
        type:"maxValue",
        item:{
          dataId:maxValue?.item?.dataId,
          column:maxValue?.item?.column,
          formula:FormatFormula(maxValue?.formula)
        }
      }
      dispatch(Gauge(payload))
    },[maxValue])


    // useEffect to listen to the custom event
useEffect(() => {
  const handleSubmitEvent = (event) => {
    submitWidget();  // Invoke your submitWidget function when event is caught
  };

  window.addEventListener("submitWidgetData", handleSubmitEvent);

  // Cleanup to remove the listener on unmount
  return () => {
    window.removeEventListener("submitWidgetData", handleSubmitEvent);
  };
});  // Empty dependency array to only run this on mount/unmount


  return (
    <div className='w-full h-auto  flex flex-col items-center gap-4  ' >
        <DropTarget
          accept={ItemTypes.NUMBER}
          label={"Minimum Value"}
          onDrop={setMinValue}
          item={minValue.item}
          setValue={setMinValue}
          formula={minValue.formula}
          setFormula={(formula) => setMinValue(prev => ({ ...prev, formula }))}
          handleDelete={() => setMinValue(prev => ({ ...prev, item:"", }))}

        />
        <DropTarget
          accept={ItemTypes.NUMBER}
          label={"Maximum Value"}
          onDrop={setMaxValue}
          item={maxValue.item}
          setValue={setMaxValue}
          formula={maxValue.formula}
          setFormula={(formula) => setMaxValue(prev => ({ ...prev, formula }))}
          handleDelete={() => setMaxValue(prev => ({ ...prev, item:"", }))}

        />
       
    </div>
  )
}

export default GaugeDrop