import React, { useEffect, useState } from "react";
import "./index.css";
import Popup from "../../../../Shared/Dashboard/popup";
import { GetAllDashboard } from "../../../../Redux/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UserProfileService from '../../../../Services/userProfile';



const CreatedDashboards = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const [userProfile, setUserProfile] = useState(() => new UserProfileService().getUserProfile());
  const { fullName, email, photoProfile } = userProfile;


  const alldashboards = useSelector((state) => state.dashboard.userDashboards)?.dashboards;
  const isLoading = useSelector((state) => state.dashboard.isDashbaordsLoading);

  const [isPopUpActive, setIsPopupActive] = useState(false);

  useEffect(() => {
    dispatch(GetAllDashboard());
  }, []);

  const handlePopup = () => {
    setIsPopupActive(true);
  };


  return (
    <div className="w-full h-auto flex flex-col gap-4 px-10 pt-10 relative   ">
      <h2 className="text-[#101828] text-[20px] font-Inter font-bold ">
        Hi, {fullName}
      </h2>
      <span className="text-new_gray_700 text-[16px] font-Inter ">
        Welcome onboard! Here's some quick resources get you started with
        Baiynah.
      </span>
      {/**Dashbaords**/}
      <div className="w-full h-auto flex flex-wrap gap-4 mt-[20px] ">
     

        {alldashboards?.length > 0 &&
          alldashboards.map((dashbaord, index) => {
            return (
              <div onClick={() => navigate(`/edit/dashboard/${dashbaord._id}`, {state: { name: dashbaord?.name } })} className="w-[220px] h-[370px] cursor-pointer flex flex-col border-[1px] rounded-lg bg-white ">
                <div className="w-full h-[50%] bg-new_purple_700 rounded-t-lg flex flex-col gap-4 items-center justify-center ">
                  <img src="/media/login/baiynah-white.svg" />
                  <h2 className="text-[12px] text-white font-Inter">
                   {dashbaord.name}
                  </h2>
                </div>
                <div className="w-full h-[50%] flex items-start flex-col">
                  <div className="flex items-start flex-col py-3 px-4  gap-2 ">
                    <img
                      src="/media/dashboard/briefcase.svg"
                      className="h-[20px]"
                    />
                    <h2 className="text-[14px] font-Inter font-semibold break-words ">
                    {dashbaord.name}
                    </h2>
                    <p className="text-[12px] font-Inter text-new_gray_700">
                      In this video, Cem will guide you through the process of
                      creating your inaugural.
                    </p>
                  </div>
                  <div className="w-full h-[60px] px-4 flex items-center justify-between border-t-[1px] cursor-pointer hover:text-new_purple_700 ">
                    <h2 className="text-[14px] font-Inter font-semibold ">
                      {" "}
                      Open Dashboard
                    </h2>
                    <img src="/media/dashboard/chevron-right.svg" />
                  </div>
                </div>
              </div>
            );
          })}

        <div
          onClick={handlePopup}
          style={{
            width: alldashboards?.length == 0 && "100%",
            height: alldashboards?.length == 0 && "250px",
          }}
          className="w-[220px] h-[370px] border-stroke flex items-center justify-center cursor-pointer gap-6 flex-col   active:scale-95 transition-all  "
        >
          <img
            style={{ height: alldashboards?.length == 0 && "65px" }}
            src="/media/dashboard/plus.svg"
          />
          <h2 className="text-[18px] text-new_purple_600 font-Inter font-semibold ">
            Create Dashboard
          </h2>
        </div>
      </div>
      {/****/}
      <Popup
        setIsPopupActive={setIsPopupActive}
        isPopUpActive={isPopUpActive}
      />
    </div>
  );
};

export default CreatedDashboards;
