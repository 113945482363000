import React, { lazy, Suspense , useState,useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { WidgetContext } from '../ChartType/DropZones/line/chartContext'
import { AddWidget } from 'src/Redux/widgetSlice'
import Spinner from "../../../../../components/spinner"



const WidgetPreview = () => {

  const { widgetData  } = useContext(WidgetContext);
  const params = useParams()
  const dispatch = useDispatch()

  const currentChart = useSelector((state)=>state.charts.currentChart)
  const chartData = useSelector((state)=>state.charts[`${currentChart}Chart`])

  console.log(chartData,"DATA CHART COND")

  const isLoading = useSelector((state)=>state.widgets.isAddWidgetLoading)

  const isSideBarActive = useSelector((state)=>state.sheet.isSideBarActive)

  const fireWidgetDataEvent = () => {

    const submitWidgetData = new CustomEvent("submitWidgetData", {
      detail: "invoke submit widget data function"
    });

    window.dispatchEvent(submitWidgetData);
  }

  // Just for adding Chart key to tab name to create the imported comp name
  const chartName = currentChart+"Chart"

  //This will import the component dynamically 
  const ChartComponent = lazy (()=>import(`../ChartType/charts/${chartName}`));

  // Toggling Sidebar Width
  const width = isSideBarActive ? "w-[calc(100%-690px)]" : "w-[calc(100%-410px)]" 

  return (
    <div  className={`${width} h-full flex flex-col items-center justify-center `} >
      {/**Widget Modal**/}
       <div className='w-[98%] h-[95%] bg-white border-[1px] border-new_gray_50 rounded-md ' >
         <div className='w-full h-[60px] border-b-[1px] border-new_gray_200 flex items-center justify-between px-4 ' >
           <div className='flex items-center gap-4' > 
             <img src='/media/add-widgets/int-1.png' className='h-[30px]' />
             <input type='text' placeholder='Add a widget title' className='outline-none' /> 
           </div>
           <div onClick={ fireWidgetDataEvent } className="w-[150px] h-[40px] bg-blue-400 rounded-md cursor-pointer active:scale-95 transition-all  text-white font-Inter inline-flex items-center justify-center " >
         
            {
              isLoading ? <Spinner/> :   "Add Widget"
            }
           </div>
         </div>
         <div className='w-full h-[calc(100%-60px)] flex items-center justify-center relative  p-5 ' >
           {/* <LineChart  /> */}
            <Suspense  fallback={<Spinner />}>
              <ChartComponent chartData={chartData}/>
            </Suspense>
           {/* <h2 className='text-[60px] font-Inter font-semibold ' >350K</h2> */}
         </div>
       </div>
    </div>
  )
}

export default WidgetPreview