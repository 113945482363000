import React,{useEffect, useState} from 'react'
import WidgetPlayGround from './widgetsPlayground'
import { useLocation, useNavigate ,useParams } from 'react-router-dom'
import { UpdateDashbaord } from '../../../Redux/dashboardSlice'
import { useDispatch } from 'react-redux'


const EditDashboard = () => {

  const {state} = useLocation()
  const dispatch = useDispatch()

  console.log(state,"NAME")


  const {id } = useParams();

  const navigate = useNavigate()

  const handleSave = (widgets) => {
   const payload = {
      dashboardId:id,
      widgets:widgets
   }

   dispatch(UpdateDashbaord(payload))
  }

  

  return (
    <div className='w-[100vw] h-[100vh] flex flex-col bg-background_color ' >
         {/***Header*/}
      <div className='w-full h-[70px] flex flex-row  justify-between px-10   border-b-[1px] border-new_gray_100 bg-white ' >
        {/**Header Left Side**/}
         <div className='sm:w-full lg:w-[25%] 2xl:w-[20%]  flex flex-row gap-6 items-center ' >
           {/**Website Logo**/}
            <div className='flex flex-row items-center gap-6  ' >
              <img onClick={()=>navigate("/create/dashboard")} src='/media/add-widgets/baiynah.svg'  className='h-[30px] cursor-pointer'  />
              <div className=' bg-new_gray_200 w-[1px] h-[35px] ' ></div>
            </div>
            {/***Active Tabs**/}
            <div className='flex flex-row items-center gap-4' >
              <span  className='text-[14px] cursor-pointer text-new_gray_900 text-new  font-Inter font-semibold'>Dashboard</span>   
              <span className='w-[1px] h-[22px] bg-new_gray_200  rotate-12' ></span>           
              <span className='text-[14px] text-new_gray_900 text-new  font-Inter font-semibold'>{state?.name}</span>              
            </div>

         </div>
         <div className=' sm:w-0 lg:w-[55%] 2xl:w-[60%] flex flex-row gap-8 items-center justify-center  ' >
          <img src='/media/dashboard/share.svg' className='h-[20px] cursor-pointer active:scale-90 transition-all  ' />
          <img src='/media/dashboard/rotate.svg' className='h-[20px] cursor-pointer active:scale-90 transition-all' />
          <img src='/media/dashboard/user-plus.svg' className='h-[20px] cursor-pointer active:scale-90 transition-all' />
          <img src='/media/dashboard/airplay.svg' className='h-[20px] cursor-pointer active:scale-90 transition-all' />
          <img src='/media/dashboard/maximize.svg' className='h-[20px] cursor-pointer active:scale-90 transition-all' />
          <img src='/media/dashboard/more-vertical.svg' className='h-[20px] cursor-pointer active:scale-90 transition-all' />
         </div>
        {/**Header Right Side**/}
        <div className='sm:w-0 lg:w-[20%] sm:hidden lg:flex flex flex-row gap-4 items-center justify-end ' >
        {/* <div onClick={handleSave} className='flex flex-row gap-2 rounded-md border-[1px] border-new_gray_300 px-4 py-2 bg-white shadow-buttons_shadow cursor-pointer active:scale-90 transition-all ' >
           <img src='/media/dashboard/plus-blue.svg'/>
           <span className='font-Inter font-semibold' >Save</span>
         </div>    */}
         
         <div onClick={()=>navigate(`/dashboard/${id}/create-widgets`)} className='flex flex-row gap-2 rounded-md border-[1px] border-new_gray_300 px-4 py-2 bg-white shadow-buttons_shadow cursor-pointer active:scale-90 transition-all ' >
           <img src='/media/dashboard/plus-blue.svg'/>
           <span className='font-Inter font-semibold' >Add Widget</span>
         </div>   
        

        </div>
      </div>
      <WidgetPlayGround handleSave={handleSave} />
    </div>
  )
}

export default EditDashboard