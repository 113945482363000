import { API  } from "./api";


const Dashboard = {
  createDashboard: (payload)=> API.post("new/dashboard",payload),
  getAllDashboads:()=>API.get("new/dashboards"),
  getSingleDashboard:(params)=>API.get(`dashboard?dashboardId=${params}`),
  updateDashboard:(payload)=>API.put(`dashboard/widgets`,payload)
}

export default Dashboard;
