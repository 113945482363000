import React, { useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import { useParams } from "react-router-dom";
import {useDispatch} from "react-redux"
import DropedColumn from "./dropedColumn";
import { Calculate } from "../../../../../../../Redux/chartsSlice";
import { FormatFormula } from "../Formula";
import { AddWidget } from 'src/Redux/widgetSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'


const NumberDrop = () => {

  const dispatch = useDispatch()
  const params = useParams()

  const [columns, setColumns] = useState();
  const [activeformula,setActiveFormula] = useState("Sum")

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "number",
    drop: (item) => {
      if (item?.column?.dataType == "number") {
        setColumns(item.column);
      } else {
        return;
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });


  const isActive = canDrop ;

  const submitWidget = (event) => {

    const dashboardId = params?.id;
  
    if (columns) {

      const dataObject = {col:columns?.value,formula:FormatFormula(activeformula)}
      

      const metaData = {
        dashboardId,
        value:dataObject,
        dataId:columns?.dataId,
        chartType: "number",  
      };

      dispatch(AddWidget(metaData)).then((resp)=>{
        if(resp?.payload.message == "Widget added successfully"){
          toast.success(`Widget Saved Successfully`, {
            position: "top-right",
           },5000)
        }
        else{
          toast.error(`Error while saving widget`, {
            position: "top-right",
           },5000)
        }
      })
    
      console.log(metaData, "DASHBOARD DATA");
    // Here you might also call an API or update some state with this metaData
    }else{
      console.warn("DATA MISSING")
    }
  
  };

  useEffect(()=>{

    if(!columns){
      return 
    }
    
    
    const payload = {
      dataId:columns.dataId,
      column:columns.column,
      formula:FormatFormula(activeformula)
    }

    dispatch(Calculate(payload))
  },[columns,activeformula])

  useEffect(() => {
    const handleSubmitEvent = (event) => {
      submitWidget();  // Invoke your submitWidget function when event is caught
    };
  
    window.addEventListener("submitWidgetData", handleSubmitEvent);
  
    // Cleanup to remove the listener on unmount
    return () => {
      window.removeEventListener("submitWidgetData", handleSubmitEvent);
    };
  });  // Empty dependency array to only run this on mount/unmount
  
  

  
  return (
    <div className="w-full h-auto border-[1px]  flex flex-col">
      <div className="w-full h-[40px] flex items-center justify-start px-4 text-[12px] font-Inter border-b-[1px]  ">
        METRIC (IN COLUMNS)
      </div>
      <div className="w-full min-h-[40px] max-h-auto flex flex-col gap-2 ">
        {columns?.value && (
          <DropedColumn columns={columns} setColumns={setColumns}  setActiveFormula={setActiveFormula} activeformula={activeformula}/>
        )}

        <div className="p-2">
          <div
            ref={drop}
            className={`w-full h-[45px] border-[3px] ${isActive ? "border-blue-200" : " "} rounded-sm border-dashed border-new_gray_200  p-1`}
          >
            <div
              className={` w-full h-full flex gap-2 items-center justify-center rounded-sm  ${isActive ? " bg-blue-300 " : ""}  `}
            >
              {isActive ? (
                <span className="text-[14px] font-Inter text-white ">
                  Drop to Add
                </span>
              ) : (
                <>
                  <span className="text-[14px] font-Inter  ">Drag</span>
                  <span className="text-[14px] font-Inter text-green-400  ">
                    123
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NumberDrop;
